import React from "react";
import { Button, Modal, Select, Input, Form } from "antd";

import styled from "styled-components";
import { capitalizeFirstLetter } from "@helpers/tools";
type Props = {
  formDetailsData: object;
  isModalVisible: boolean;
  setIsModalVisible: Function;
  onHandleFormSubmit: Function;
  formDetailsData: Object;
};

export const CreateAccountModal: React.FC<Props> = ({
  formDetailsData,
  isModalVisible,
  setIsModalVisible,
  onHandleFormSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const [accountSelectType, setAccountSelectType] = React.useState(
    formDetailsData?.accountTypes?.data?.[1]?.id
  );

  return (
    <CreateAccountModalWrapper>
      <Modal
        centered={true}
        title="Create Account"
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          form={form}
          name="create_acc"
          layout="vertical"
          onFinish={(values: any) => onHandleFormSubmit(values)}
          scrollToFirstError
          initialValues={{
            asset_type: formDetailsData?.assetTypes?.data?.[0]?.id,
            account_type: formDetailsData?.accountTypes?.data?.[1]?.id,
            init_bal: 0,
            credit_limit: 0,
            currency: formDetailsData?.currencies?.data?.[0]?.id,
          }}
        >
          <Form.Item
            name="name"
            label="Account Name"
            rules={[
              {
                required: true,
                message: "Please input your account name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="init_bal"
            label="Initial Balance"
            rules={[
              {
                required: true,
                message: "Please input your Initial Balance!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="card_number"
            label="Card Digit"
            tooltip="last four digits"
            rules={[
              {
                required: true,
                message: "Please input your Card Digit!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="asset_type"
            label="Assets Type"
            tooltip="Non personal will no added to final sums"
            rules={[{ required: true, message: "Please select asset type!" }]}
          >
            <Select placeholder="select assets type">
              {formDetailsData?.assetTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: "Please select Currency !" }]}
          >
            <Select placeholder="select currency">
              {formDetailsData?.currencies?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[{ required: true, message: "Please select Account!" }]}
          >
            <Select
              placeholder="select account"
              onChange={val => setAccountSelectType(val)}
            >
              {formDetailsData?.accountTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {accountSelectType === "1" && (
            <Form.Item
              name="credit_limit"
              label="Credit Limit"
              rules={[
                {
                  required: true,
                  message: "Please input your Credit Limit!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item name="remark" label="Remark">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          <Button type="primary" htmlType="submit" block>
            Create
          </Button>
        </Form>
      </Modal>
    </CreateAccountModalWrapper>
  );
};

const CreateAccountModalWrapper = styled.div``;
