import React from "react";
import { Button, Modal, Input } from "antd";

import styled from "styled-components";

type Props = {
  modalVis: boolean;
  setModalVis: Function;
  Form: any;
  form: any;
  onHandleFormSubmit: Function;
  initialBal: boolean;
};

export const EditBalanceModal: React.FC<Props> = ({
  modalVis,
  setModalVis,
  Form,
  form,
  onHandleFormSubmit,
  initialBal,
}: Props) => {
  return (
    <EditBalanceModalWrapper>
      <Modal
        centered={true}
        title={<>Update Balance</>}
        open={modalVis}
        footer={null}
        onCancel={() => setModalVis(false)}
      >
        <Form
          form={form}
          name="edit_balance"
          layout="vertical"
          onFinish={(values: any) => onHandleFormSubmit(values)}
          scrollToFirstError
          initialValues={{
            balance: initialBal?.toFixed(2),
          }}
        >
          <Form.Item
            name="balance"
            label="Balance"
            rules={[
              {
                required: true,
                message: "Please input your balance!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Button type="primary" htmlType="submit" block>
            Edit
          </Button>
        </Form>
      </Modal>
    </EditBalanceModalWrapper>
  );
};

const EditBalanceModalWrapper = styled.div``;
