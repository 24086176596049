import React from "react";
import { Button, Modal, Select, Input, DatePicker, Divider, Space } from "antd";
import type { InputRef } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { capitalizeFirstLetter } from "@helpers/tools";
import { durationOpts } from "@helpers/const";

type Props = {
  formDetailsData: object;
  modalVis: boolean;
  setModalVis: Function;
  Form: any;
  form: any;
  onHandleFormSubmit: Function;
};

export const EditRecurrentModal: React.FC<Props> = ({
  formDetailsData,
  modalVis,
  setModalVis,
  Form,
  form,
  onHandleFormSubmit,
}: Props) => {
  const inputRef = React.useRef<InputRef>(null);

  return (
    <EditRecurrentModalWrapper>
      <Modal
        centered={true}
        title={"Update Recurrent"}
        open={modalVis}
        footer={null}
        onCancel={() => {
          setModalVis(false);
        }}
      >
        <Form
          form={form}
          name="update_tran"
          layout="vertical"
          onFinish={(values: any) => onHandleFormSubmit(values)}
          scrollToFirstError
        >
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please input your amount!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="transaction_type"
            label="Transact Type"
            rules={[
              { required: true, message: "Please select transact type!" },
            ]}
          >
            <Select placeholder="select transact type">
              {formDetailsData?.transactionTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true, message: "Please select duration!" }]}
          >
            <Select placeholder="select Duration">
              {durationOpts.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.option)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="account"
            label="Account"
            rules={[{ required: true, message: "Please select Account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accountTypes?.data?.map(res => {
                return (
                  <Select.OptGroup
                    key={res.id}
                    label={capitalizeFirstLetter(res.attributes.name)}
                  >
                    {formDetailsData?.accounts?.data?.map(get => {
                      if (res.id === get.attributes.account_type.data.id)
                        return (
                          <Select.Option key={get.id} value={get.id}>
                            {capitalizeFirstLetter(get.attributes.name)}
                          </Select.Option>
                        );
                    })}
                  </Select.OptGroup>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="transaction_tag"
            label="Tags"
            rules={[{ required: true, message: "Please select tags" }]}
          >
            <Select
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      value={name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setName(event.target.value);
                      }}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault();
                        setItems([...items, name || `New item ${index++}`]);
                        setName("");
                        setTimeout(() => {
                          inputRef.current?.focus();
                        }, 0);
                      }}
                    >
                      Add Tags
                    </Button>
                  </Space>
                </>
              )}
            >
              {formDetailsData?.transactionTags?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="publish_date"
            label="Transact Publish Date"
            rules={[{ required: true, message: "Please select datetime!" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item name="remark" label="Remarks">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          {/* <Form.Item> */}
          <Button type="primary" htmlType="submit" block>
            Edit
          </Button>
          {/* </Form.Item> */}
        </Form>
      </Modal>
    </EditRecurrentModalWrapper>
  );
};

const EditRecurrentModalWrapper = styled.div``;
