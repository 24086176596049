import React, { useEffect, useState } from "react";
import {
  Divider,
  List,
  Skeleton,
  Avatar,
  Dropdown,
  Row,
  Form,
  Col,
  Modal,
} from "antd";
import { DeleteOutlined, DeleteFilled } from "@ant-design/icons";
import { AccountsHeader } from "@components/common/accountsHeader";
import { navigate } from "gatsby";
import { URLS } from "@helpers/const";
import { accountTypeTitle } from "@helpers/enums";
import { InfiniteScroll, DotLoading } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";

import {
  capitalizeFirstLetter,
  getFirstLetter,
  capitalizeWholeLetter,
} from "@helpers/tools";
import { CreateTransactionModal } from "@components/common/createTransactionModal";
import { CreateInterTransactionModal } from "@components/common/createInterTransactionModal";
import { EditAccountModal } from "@components/common/editAccountModal";
import { EditOutlined } from "@ant-design/icons";
import { EditTransactionModal } from "@components/common/editTransactionModal";
import { EditBalanceModal } from "@components/common/editBalanceModal";
import { EmptyContent } from "@components/common/emptyContent";

import {
  withMutateTransact,
  withMutateAccount,
} from "@helpers/hoc/withMutations";
import { withQueryFormDetails } from "@helpers/hoc/withQueries";

import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";
import useBreakpoints from "@helpers/hooks/breakPoints";
import styled from "styled-components";
import SEO from "@components/common/seo";
import { getUser } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";
import { formatISODate } from "@helpers/tools";
import dayjs from "dayjs";

type Props = {
  location: object;
  deleteTransactionData: object;
  deleteTransaction: Function;
  updateTransaction: Function;
  updateAccount: Function;
  deleteAccount: Function;
  createTransaction: Function;
  createTransactionData: object;
  accountID: string;
  formDetailsData: object;
  updateAccountData: object;
};

const Accounts = ({
  deleteTransaction,
  deleteTransactionData,
  updateTransaction,
  accountID,
  formDetailsData,
  updateAccount,
  updateAccountData,
  deleteAccount,
  createTransaction,
  createTransactionData,
}: Props) => {
  const { id } = getUser();
  const [editID, setEditID] = useState();
  const [isEditTranModalVis, setIsEditTranModalVis] = useState(false);
  const [isEditAccModalVis, setIsEditAccModalVis] = useState(false);
  const [isEditBalanceModalVis, setIsEditBalanceModalVis] = useState(false);
  const [isCreateTransactModalVisible, setIsCreateTransactModalVisible] =
    useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [
    isCreateInterTransactModalVisible,
    setIsCreateInterTransactModalVisible,
  ] = useState(false);
  const [form] = Form.useForm();
  const point = useBreakpoints();

  const breakMDBelow = point !== "md" && point !== "sm";
  const {
    data: transactionsData,
    loading: transactionsLoading,
    fetchMore: transactionsFetchMore,
    refetch: transactionsRefetch,
  } = useQuery(queries.transactions, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      filters: {
        user: { id: { eq: Number(id) } },
        account: { id: { eq: Number(accountID) } },
      },
      sort: "date_time:desc",
    },
  });

  const hasMore = !(
    transactionsData?.transactions?.data?.length ===
    transactionsData?.transactions?.meta?.pagination?.total
  );
  const { data: accountSummaryData, refetch: accountSummaryRefetch } = useQuery(
    queries.accountSummary,
    {
      variables: { id: accountID },
    }
  );

  const accountDetails = `(${capitalizeFirstLetter(
    accountSummaryData?.accountSummary?.data?.account_type?.name
  )} Account , ${capitalizeWholeLetter(
    accountSummaryData?.accountSummary?.data?.currency?.name
  )}, ${accountSummaryData?.accountSummary?.data?.card_number})`;

  const title = `${capitalizeFirstLetter(
    accountSummaryData?.accountSummary?.data?.name
  )} ${capitalizeFirstLetter(
    accountSummaryData?.accountSummary?.data?.asset_type?.name
  )} ${breakMDBelow ? accountDetails : ""}`;

  const isDebit =
    accountSummaryData?.accountSummary?.data?.account_type?.id === 2;

  useEffect(() => {
    transactionsRefetch();
    transactionsRefetch();
  }, [deleteTransactionData, createTransactionData]);
  useEffect(() => {
    accountSummaryRefetch();
  }, [updateAccountData, createTransactionData]);

  return (
    <AccountsWrapper>
      <SEO title={title} />
      <AccountsHeader
        title={title}
        statisticTitle={{
          first: `Balance`,
          firstAmt: accountSummaryData?.accountSummary?.data?.balance,
          second:
            accountTypeTitle[
              accountSummaryData?.accountSummary?.data?.account_type?.id
            ],
          secondAmt: accountSummaryData?.accountSummary?.data?.init_bal,
          third: isDebit ? undefined : "Credit Limit",
          thirdAmt: isDebit
            ? undefined
            : `${accountSummaryData?.accountSummary?.data?.credit_balance?.toFixed(
                2
              )}/${accountSummaryData?.accountSummary?.data?.credit_limit}`,
        }}
        prefix={accountSummaryData?.accountSummary?.data?.currency?.name.toUpperCase()}
        handleEditBalance={() => {
          setIsEditBalanceModalVis(true);
        }}
        handleCreateTransaction={() => setIsCreateTransactModalVisible(true)}
        handleCreateInterTransaction={() =>
          setIsCreateInterTransactModalVisible(true)
        }
        handleSetting={() => {
          setIsEditAccModalVis(true);
          form.setFieldsValue({
            asset_type:
              accountSummaryData?.accountSummary?.data?.asset_type?.id.toString(),
            account_type:
              accountSummaryData?.accountSummary?.data?.account_type?.id.toString(),
            init_bal:
              accountSummaryData?.accountSummary?.data?.init_bal.toString(),
            credit_limit:
              accountSummaryData?.accountSummary?.data?.credit_limit?.toString(),
            card_number:
              accountSummaryData?.accountSummary?.data?.card_number.toString(),
            currency:
              accountSummaryData?.accountSummary?.data?.currency?.id.toString(),
            balance:
              accountSummaryData?.accountSummary?.data?.balance.toString(),
            name: accountSummaryData?.accountSummary?.data?.name,
            remark: accountSummaryData?.accountSummary?.data?.remark,
          });
        }}
      />

      <Divider orientation="left">Transactions</Divider>
      <div className="mx-10">
        <>
          {transactionsLoading ? (
            <Skeleton
              avatar
              title={true}
              loading={true}
              active
              paragraph={{ rows: 10 }}
            />
          ) : (
            <>
              {transactionsData?.transactions?.data?.length > 0 ? (
                <>
                  {transactionsData?.transactions?.data?.map((res, index) => {
                    return (
                      <List
                        key={index}
                        style={{ marginBottom: "1.5rem" }}
                        header={
                          <div>
                            <Row>
                              <Col span={12}>
                                {formatISODate(res.attributes.date_time)}
                                <Divider type="vertical" />
                                {capitalizeFirstLetter(
                                  res?.attributes?.transaction_type?.data
                                    ?.attributes?.name
                                )}
                                <Divider type="vertical" />
                                {res.attributes.amount}
                                <Divider type="vertical" />
                              </Col>
                              <Col span={12}>
                                <Dropdown.Button
                                  className="justify-end"
                                  onClick={() => {
                                    setEditID(Number(res.id));
                                    setIsEditTranModalVis(true);
                                    form.setFieldsValue({
                                      transaction_type:
                                        res?.attributes?.transaction_type?.data
                                          ?.id,
                                      account:
                                        res?.attributes?.account?.data?.id,
                                      transaction_tag:
                                        res?.attributes?.transaction_tag?.data
                                          ?.id,
                                      date_time: dayjs(
                                        res?.attributes?.date_time
                                      ),
                                      amount: Number(res?.attributes?.amount),
                                      remark: res?.attributes?.remark,
                                    });
                                  }}
                                  size="small"
                                  loading={false}
                                  menu={{
                                    items: [
                                      {
                                        key: "1",
                                        label: "Delete",
                                        icon: <DeleteOutlined />,
                                      },
                                    ],
                                    onClick: () => {
                                      Modal.confirm({
                                        title:
                                          "Confirm to Delete this Transaction?",
                                        icon: <DeleteFilled />,
                                        okType: "danger",
                                        okText: (
                                          <>
                                            <DeleteOutlined /> Yes
                                          </>
                                        ),
                                        onOk() {
                                          console.log("OK");
                                          deleteTransaction({
                                            variables: { id: res.id },
                                          });
                                        },
                                        onCancel() {
                                          console.log("Cancel");
                                        },
                                      });
                                    },
                                  }}
                                >
                                  <EditOutlined />
                                  Edit
                                </Dropdown.Button>
                              </Col>
                            </Row>
                          </div>
                        }
                        itemLayout="horizontal"
                        dataSource={[res.attributes]}
                        renderItem={item => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar>
                                  {getFirstLetter(
                                    item.transaction_tag?.data?.attributes?.name
                                  )}
                                </Avatar>
                              }
                              title={
                                <a href="#">
                                  {capitalizeFirstLetter(
                                    item.transaction_tag?.data?.attributes?.name
                                  )}
                                </a>
                              }
                              description={capitalizeFirstLetter(
                                item.account?.data?.attributes?.name
                              )}
                            />
                            <div>
                              {res?.attributes?.transaction_type?.data?.id ===
                              "1"
                                ? "+"
                                : "-"}
                              {item.amount}
                            </div>
                          </List.Item>
                        )}
                      />
                    );
                  })}
                  <div className="w-full mb-3 text-center">
                    <InfiniteScroll
                      loadMore={async () => {
                        const counts = 15;
                        transactionsFetchMore({
                          variables: {
                            pagination: {
                              pageSize: pageSize + counts,
                            },
                          },
                        });
                        setPageSize(pageSize + counts);
                        await sleep(2000);
                      }}
                      hasMore={hasMore}
                    >
                      <>
                        {hasMore ? (
                          <>
                            <span>Loading</span>
                            <DotLoading />
                          </>
                        ) : (
                          <span>--- All Fetched ---</span>
                        )}
                      </>
                    </InfiniteScroll>
                  </div>
                </>
              ) : (
                <EmptyContent />
              )}
            </>
          )}
        </>
      </div>
      <EditTransactionModal
        formDetailsData={formDetailsData}
        Form={Form}
        form={form}
        isEditTranModalVis={isEditTranModalVis}
        setIsEditTranModalVis={setIsEditTranModalVis}
        onHandleFormSubmit={values => {
          values.amount = Number(values.amount);
          updateTransaction({
            variables: {
              id: editID,
              data: values,
            },
          });
          setIsEditTranModalVis(false);
        }}
      />
      <EditAccountModal
        formDetailsData={formDetailsData}
        Form={Form}
        form={form}
        isDebit={isDebit}
        isEditAccModalVis={isEditAccModalVis}
        setIsEditAccModalVis={setIsEditAccModalVis}
        onHandleFormSubmit={values => {
          values.user = id;
          values.init_bal = parseFloat(values.init_bal);
          if (values.credit_limit)
            values.credit_limit = parseFloat(values.credit_limit);
          updateAccount({
            variables: {
              id: accountID,
              data: values,
            },
          });
          setIsEditAccModalVis(false);
        }}
        handleDeleteAccount={() => {
          Modal.confirm({
            title: "Confirm to Delete this Account?",
            icon: <DeleteFilled />,
            okType: "danger",
            okText: (
              <>
                <DeleteOutlined /> Yes
              </>
            ),
            onOk() {
              console.log("OK");
              deleteAccount({
                variables: { id: accountID },
              });
              navigate(URLS.home);
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        }}
      />
      <EditBalanceModal
        Form={Form}
        form={form}
        modalVis={isEditBalanceModalVis}
        setModalVis={setIsEditBalanceModalVis}
        initialBal={accountSummaryData?.accountSummary?.data?.balance}
        onHandleFormSubmit={values => {
          const accBalance = accountSummaryData?.accountSummary?.data?.balance;
          const amount = Number(values.balance) - accBalance;
          const isNegative = amount < 0;
          const isWithdrawal = isDebit ? isNegative : !isNegative;

          createTransaction({
            variables: {
              data: {
                transaction_type: isWithdrawal ? 2 : 1,
                account: Number(accountID),
                transaction_tag: 5,
                date_time: dayjs(),
                amount: Math.abs(amount.toFixed(2)),
                user: id,
              },
            },
          });
          setIsEditBalanceModalVis(false);
          // navigate(URLS.home);
        }}
      />
      <CreateTransactionModal
        isModalVisible={isCreateTransactModalVisible}
        setIsModalVisible={setIsCreateTransactModalVisible}
        formDetailsData={formDetailsData}
        accountID={accountID}
        onHandleFormSubmit={(values, form) => {
          values.user = id;
          values.amount = Number(values.amount);

          createTransaction({
            variables: {
              data: values,
            },
          });
          form.resetFields();
          setIsCreateTransactModalVisible(false);
        }}
      />
      <CreateInterTransactionModal
        isModalVisible={isCreateInterTransactModalVisible}
        setIsModalVisible={setIsCreateInterTransactModalVisible}
        formDetailsData={formDetailsData}
        accountID={accountID}
        onHandleFormSubmit={(values, form) => {
          console.log("Received values of form: ", values);
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 2,
                account: values.from_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: id,
              },
            },
          });
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 1,
                account: values.to_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: id,
              },
            },
          });
          form.resetFields();
          setIsCreateInterTransactModalVisible(false);
        }}
      />
    </AccountsWrapper>
  );
};

export default withMutateAccount(
  withQueryFormDetails(withMutateTransact(withLocation(Accounts)))
);

const AccountsWrapper = styled.div`
  && {
    margin: 0 auto;
  }
  .ant-list-item-meta-title {
    margin-top: 0;
  }
`;
