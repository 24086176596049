import React from "react";
import { Button, Modal, Select, Input, Form, DatePicker } from "antd";
import styled from "styled-components";
import { capitalizeFirstLetter } from "@helpers/tools";
import dayjs from "dayjs";

type Props = {
  formDetailsData: object;
  isModalVisible: boolean;
  setIsModalVisible: Function;
  onHandleFormSubmit: Function;
  accountID?: ID;
};

export const CreateInterTransactionModal: React.FC<Props> = ({
  formDetailsData,
  isModalVisible,
  setIsModalVisible,
  onHandleFormSubmit,
  accountID,
}: Props) => {
  const [form] = Form.useForm();

  return (
    <CreateInterTransactionModalWrapper>
      <Modal
        title="Create Inter Transactions"
        open={isModalVisible}
        footer={null}
        centered={true}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form
          form={form}
          name="inter_tran"
          layout="vertical"
          onFinish={(values: any) => onHandleFormSubmit(values, form)}
          scrollToFirstError
          initialValues={{
            transfer_amount: 0,
            transaction_charge: 0,
            from_account: accountID || formDetailsData?.accounts?.data?.[0]?.id,
            to_account:
              formDetailsData?.accounts?.data?.[
                Math.floor(
                  Math.random() * formDetailsData?.accounts?.data?.length
                )
              ]?.id,
            date_time: dayjs(),
          }}
        >
          <Form.Item
            name="transfer_amount"
            label="Transfer Amount"
            rules={[
              {
                required: true,
                message: "Please input your Transfer Amount!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="transaction_charge"
            label="Transaction Charge"
            tooltip="bank charges for transaction"
            rules={[
              {
                required: true,
                message: "Please input your Transaction Charge!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="from_account"
            label="From"
            rules={[{ required: true, message: "Please select from account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accountTypes?.data?.map(res => {
                return (
                  <Select.OptGroup
                    key={res.id}
                    label={capitalizeFirstLetter(res.attributes.name)}
                  >
                    {formDetailsData?.accounts?.data?.map(get => {
                      if (res.id === get.attributes.account_type.data.id)
                        return (
                          <Select.Option key={get.id} value={get.id}>
                            {capitalizeFirstLetter(get.attributes.name)}
                          </Select.Option>
                        );
                    })}
                  </Select.OptGroup>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="to_account"
            label="To"
            rules={[{ required: true, message: "Please select to account!" }]}
          >
            <Select placeholder="select account">
              {formDetailsData?.accountTypes?.data?.map(res => {
                return (
                  <Select.OptGroup
                    key={res.id}
                    label={capitalizeFirstLetter(res.attributes.name)}
                  >
                    {formDetailsData?.accounts?.data?.map(get => {
                      if (res.id === get.attributes.account_type.data.id)
                        return (
                          <Select.Option key={get.id} value={get.id}>
                            {capitalizeFirstLetter(get.attributes.name)}
                          </Select.Option>
                        );
                    })}
                  </Select.OptGroup>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="date_time"
            label="Transact Period"
            rules={[{ required: true, message: "Please select datetime!" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item name="remark" label="Remarks">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          {/* <Form.Item> */}
          <Button type="primary" htmlType="submit" block>
            Create
          </Button>
          {/* </Form.Item> */}
        </Form>
      </Modal>
    </CreateInterTransactionModalWrapper>
  );
};

const CreateInterTransactionModalWrapper = styled.div``;
