import { gql } from "@apollo/client";

export const queries = {
  me: gql`
    query me {
      me {
        id
        username
        email
        role {
          id
          type
          name
        }
      }
    }
  `,
  usersPermissionsUser: gql`
    query usersPermissionsUser($id: ID!) {
      usersPermissionsUser(id: $id) {
        data {
          id
          attributes {
            createdAt
            updatedAt
            username
            email
            provider
            confirmed
            blocked
            role {
              data {
                id
                attributes {
                  type
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  accountTypes: gql`
    query accountTypes {
      accountTypes {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  `,
  accounts: gql`
    query accounts($filters: AccountFiltersInput) {
      accounts(filters: $filters) {
        data {
          id
          attributes {
            name
            card_number
            asset_type {
              data {
                attributes {
                  name
                }
              }
            }
            account_type {
              data {
                id
                attributes {
                  name
                }
              }
            }
            currency {
              data {
                attributes {
                  name
                }
              }
            }
            user {
              data {
                attributes {
                  username
                }
              }
            }
          }
        }
      }
    }
  `,
  transactions: gql`
    query transactions(
      $filters: TransactionFiltersInput
      $sort: [String]
      $pagination: PaginationArg
    ) {
      transactions(filters: $filters, sort: $sort, pagination: $pagination) {
        data {
          id
          attributes {
            amount
            remark
            date_time
            account {
              data {
                id
                attributes {
                  name
                  currency {
                    data {
                      attributes {
                        name
                      }
                    }
                  }
                }
              }
            }
            transaction_tag {
              data {
                id
                attributes {
                  name
                }
              }
            }
            transaction_type {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
    }
  `,
  accountSummaries: gql`
    query accountSummaries {
      accountSummaries @client {
        data
      }
    }
  `,
  accountSummary: gql`
    query accountSummary($id: String) {
      accountSummary(id: $id) @client {
        data
      }
    }
  `,
  transactSummaries: gql`
    query transactSummaries {
      transactSummaries @client {
        data
      }
    }
  `,
  formDetails: gql`
    query formDetails(
      $accountTypesFilters: AccountTypeFiltersInput
      $transactionTypesFilters: TransactionTypeFiltersInput
      $transactionTagsFilters: TransactionTagFiltersInput
      $assetTypesFilters: AssetTypeFiltersInput
      $currenciesFilters: CurrencyFiltersInput
      $accountFilters: AccountFiltersInput
      $pagination: PaginationArg = {}
    ) {
      accountTypes(filters: $accountTypesFilters, pagination: $pagination) {
        data {
          id
          attributes {
            name
          }
        }
      }

      transactionTypes(
        filters: $transactionTypesFilters
        pagination: $pagination
      ) {
        data {
          id
          attributes {
            name
          }
        }
      }
      transactionTags(
        filters: $transactionTagsFilters
        pagination: $pagination
      ) {
        data {
          id
          attributes {
            name
          }
        }
      }
      assetTypes(filters: $assetTypesFilters, pagination: $pagination) {
        data {
          id
          attributes {
            name
          }
        }
      }
      currencies(filters: $currenciesFilters, pagination: $pagination) {
        data {
          id
          attributes {
            name
          }
        }
      }

      accounts(filters: $accountFilters, pagination: $pagination) {
        data {
          id
          attributes {
            name
            account_type {
              data {
                id
              }
            }
          }
        }
      }
    }
  `,
  recurrents: gql`
    query recurrents(
      $filters: RecurrentFiltersInput
      $pagination: PaginationArg
    ) {
      recurrents(filters: $filters, pagination: $pagination) {
        data {
          id
          attributes {
            duration
            amount
            publish_date
            remark
            account {
              data {
                id
                attributes {
                  name
                }
              }
            }
            transaction_type {
              data {
                id
                attributes {
                  name
                }
              }
            }
            transaction_tag {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const mutation = {
  login: gql`
    mutation login($input: UsersPermissionsLoginInput!) {
      login(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  register: gql`
    mutation register($input: UserInput!) {
      register(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  createTransaction: gql`
    mutation createTransaction($data: TransactionInput!) {
      createTransaction(data: $data) {
        data {
          id
        }
      }
    }
  `,
  updateTransaction: gql`
    mutation updateTransaction($id: ID!, $data: TransactionInput!) {
      updateTransaction(id: $id, data: $data) {
        data {
          id
        }
      }
    }
  `,
  deleteTransaction: gql`
    mutation deleteTransaction($id: ID!) {
      deleteTransaction(id: $id) {
        data {
          id
        }
      }
    }
  `,
  createTransactionTag: gql`
    mutation createTransactionTag($data: TransactionTagInput!) {
      createTransactionTag(data: $data) {
        data {
          id
        }
      }
    }
  `,
  updateTransactionTag: gql`
    mutation updateTransactionTag($data: TransactionTagInput!) {
      updateTransactionTag(data: $data) {
        data {
          id
        }
      }
    }
  `,
  deleteTransactionTag: gql`
    mutation deleteTransactionTag($data: TransactionTagInput!) {
      deleteTransactionTag(data: $data) {
        data {
          id
        }
      }
    }
  `,
  createAccount: gql`
    mutation createAccount($data: AccountInput!) {
      createAccount(data: $data) {
        data {
          id
        }
      }
    }
  `,
  updateAccount: gql`
    mutation updateAccount($id: ID!, $data: AccountInput!) {
      updateAccount(id: $id, data: $data) {
        data {
          id
        }
      }
    }
  `,
  deleteAccount: gql`
    mutation deleteAccount($id: ID!) {
      deleteAccount(id: $id) {
        data {
          id
        }
      }
    }
  `,
  createRecurrent: gql`
    mutation createRecurrent($data: RecurrentInput!) {
      createRecurrent(data: $data) {
        data {
          id
        }
      }
    }
  `,
  updateRecurrent: gql`
    mutation updateRecurrent($id: ID!, $data: RecurrentInput!) {
      updateRecurrent(id: $id, data: $data) {
        data {
          id
        }
      }
    }
  `,
  deleteRecurrent: gql`
    mutation deleteRecurrent($id: ID!) {
      deleteRecurrent(id: $id) {
        data {
          id
        }
      }
    }
  `,
};
