import React from "react";
import { Button, Modal, Select, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { capitalizeFirstLetter } from "@helpers/tools";

type Props = {
  formDetailsData: object;
  isEditAccModalVis: boolean;
  setIsEditAccModalVis: Function;
  onHandleFormSubmit: Function;
  handleDeleteAccount: Function;
  formDetailsData: Object;
  Form: any;
  form: any;
  isDebit: boolean;
};

export const EditAccountModal: React.FC<Props> = ({
  formDetailsData,
  isEditAccModalVis,
  setIsEditAccModalVis,
  onHandleFormSubmit,
  handleDeleteAccount,
  Form,
  form,
  isDebit = true,
}: Props) => {
  const [showCreditLimit, setShowCreditLimit] = React.useState();

  React.useEffect(() => {
    setShowCreditLimit(!isDebit);
  }, [isDebit]);

  return (
    <EditAccountModalWrapper>
      <Modal
        centered={true}
        title={
          <>
            Update Account
            <DeleteOutlined
              className=" cursor-pointer ml-4 !inline-grid"
              onClick={() => handleDeleteAccount()}
            />
          </>
        }
        open={isEditAccModalVis}
        footer={null}
        onCancel={() => setIsEditAccModalVis(false)}
      >
        <Form
          form={form}
          name="create_acc"
          layout="vertical"
          onFinish={(values: any) => onHandleFormSubmit(values)}
          scrollToFirstError
          initialValues={{
            asset_type: formDetailsData?.assetTypes?.data?.[0]?.id,
            account_type: formDetailsData?.accountTypes?.data?.[1]?.id,
            init_bal: 0,
            credit_limit: 0,
            currency: formDetailsData?.currencies?.data?.[0]?.id,
          }}
        >
          <Form.Item
            name="name"
            label="Account Name"
            rules={[
              {
                required: true,
                message: "Please input your account name!",
                // ,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="init_bal"
            label="Initial Balance"
            rules={[
              {
                required: true,
                message: "Please input your Initial Balance!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="card_number"
            label="Card Digit"
            tooltip="last four digits"
            rules={[
              {
                required: true,
                message: "Please input your Card Digit!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="asset_type"
            label="Assets Type"
            tooltip="Non personal will no added to final sums"
            rules={[{ required: true, message: "Please select asset type!" }]}
          >
            <Select placeholder="select assets type">
              {formDetailsData?.assetTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: "Please select Currency !" }]}
          >
            <Select placeholder="select currency">
              {formDetailsData?.currencies?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[{ required: true, message: "Please select Account!" }]}
          >
            <Select
              placeholder="select account"
              onChange={val => setShowCreditLimit(val === "1" || val === "3")}
            >
              {formDetailsData?.accountTypes?.data?.map(res => {
                return (
                  <Select.Option key={res.id} value={res.id}>
                    {capitalizeFirstLetter(res.attributes.name)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {showCreditLimit && (
            <Form.Item
              name="credit_limit"
              label="Credit Limit"
              rules={[
                {
                  required: true,
                  message: "Please input your Credit Limit!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item name="remark" label="Remark">
            <Input.TextArea showCount maxLength={100} />
          </Form.Item>

          <Button type="primary" htmlType="submit" block>
            Update
          </Button>
        </Form>
      </Modal>
    </EditAccountModalWrapper>
  );
};

const EditAccountModalWrapper = styled.div``;
