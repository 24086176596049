import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Layout as AntLayout } from "antd";

import Footer from "./footer";
import withLocation from "@helpers/hoc/withLocation";
import { URLS } from "@helpers/const";

const { Content } = AntLayout;

type Props = {
  location?: {
    pathname: string;
  };
};

const Layout: React.FC = ({ children, location }: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <AntLayout style={{ overflow: "hidden" }}>
            <Content style={{ paddingBottom: "80px" }}>
              <main>{children}</main>
            </Content>
            {!(location.pathname === URLS.login) && <Footer />}
          </AntLayout>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withLocation(Layout);
