import React from "react";
import { useMutation } from "@apollo/client";
import { mutation, queries } from "@helpers/queryStrings";

import { getUser } from "@services/auth";

export const withMutateTransact =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const { id } = getUser();
    const [createTransaction, createTransactionData] = useMutation(
      mutation.createTransaction,
      {
        refetchQueries: [
          {
            query: queries.transactions,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              sort: "date_time:desc",
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );
    const [deleteTransaction, deleteTransactionData] = useMutation(
      mutation.deleteTransaction,
      {
        refetchQueries: [
          {
            query: queries.transactions,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              sort: "date_time:desc",
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );

    const [updateTransaction, updateTransactionData] = useMutation(
      mutation.updateTransaction,
      {
        refetchQueries: [
          {
            query: queries.transactions,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              sort: "date_time:desc",
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );

    return (
      <ComponentToWrap
        {...props}
        createTransactionData={createTransactionData}
        createTransaction={createTransaction}
        deleteTransactionData={deleteTransactionData}
        deleteTransaction={deleteTransaction}
        updateTransaction={updateTransaction}
        updateTransactionData={updateTransactionData}
      />
    );
  };

export const withMutateAccount =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [createAccount, createAccountData] = useMutation(
      mutation.createAccount,
      {
        refetchQueries: [
          {
            query: queries.accountSummaries,
          },
        ],
      }
    );
    const [updateAccount, updateAccountData] = useMutation(
      mutation.updateAccount,
      {
        refetchQueries: [
          {
            query: queries.accountSummaries,
          },
        ],
      }
    );
    const [deleteAccount, deleteAccountData] = useMutation(
      mutation.deleteAccount,
      {
        refetchQueries: [
          {
            query: queries.accountSummaries,
          },
        ],
      }
    );

    return (
      <ComponentToWrap
        {...props}
        createAccountData={createAccountData}
        createAccount={createAccount}
        updateAccountData={updateAccountData}
        updateAccount={updateAccount}
        deleteAccountData={deleteAccountData}
        deleteAccount={deleteAccount}
      />
    );
  };

export const withMutateRecurrent =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const [createRecurrent, createRecurrentData] = useMutation(
      mutation.createRecurrent,
      {
        refetchQueries: [
          {
            query: queries.recurrents,
          },
        ],
      }
    );

    const [updateRecurrent, updateRecurrentData] = useMutation(
      mutation.updateRecurrent,
      {
        refetchQueries: [
          {
            query: queries.recurrents,
          },
        ],
      }
    );
    const [deleteRecurrent, deleteRecurrentData] = useMutation(
      mutation.deleteRecurrent,
      {
        refetchQueries: [
          {
            query: queries.recurrents,
          },
        ],
      }
    );

    return (
      <ComponentToWrap
        {...props}
        createRecurrentData={createRecurrentData}
        createRecurrent={createRecurrent}
        updateRecurrentData={updateRecurrentData}
        updateRecurrent={updateRecurrent}
        deleteRecurrentData={deleteRecurrentData}
        deleteRecurrent={deleteRecurrent}
      />
    );
  };

export const withMutateTransactionTag =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const { id } = getUser();
    const [createTransactionTag, createTransactionTagData] = useMutation(
      mutation.createTransactionTag,
      {
        refetchQueries: [
          {
            query: queries.formDetails,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              pagination: {
                pageSize: 20,
              },
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );
    const [deleteTransactionTag, deleteTransactionTagData] = useMutation(
      mutation.deleteTransactionTag,
      {
        refetchQueries: [
          {
            query: queries.formDetails,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              pagination: {
                pageSize: 20,
              },
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );

    const [updateTransactionTag, updateTransactionTagData] = useMutation(
      mutation.updateTransactionTag,
      {
        refetchQueries: [
          {
            query: queries.formDetails,
            variables: {
              filters: {
                user: { id: { eq: Number(id) } },
              },
              pagination: {
                pageSize: 20,
              },
            },
          },
          { query: queries.accountSummaries },
          { query: queries.transactSummaries },
        ],
      }
    );

    return (
      <ComponentToWrap
        {...props}
        createTransactionTag={createTransactionTag}
        createTransactionTagData={createTransactionTagData}
        deleteTransactionTag={deleteTransactionTag}
        deleteTransactionTagData={deleteTransactionTagData}
        updateTransactionTag={updateTransactionTag}
        updateTransactionTagData={updateTransactionTagData}
      />
    );
  };
