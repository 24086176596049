import React, { useState } from "react";
import {
  Divider,
  List,
  Skeleton,
  Avatar,
  Dropdown,
  Row,
  Col,
  Modal,
  Form,
} from "antd";
import { InfiniteScroll, DotLoading } from "antd-mobile";
import { EmptyContent } from "@components/common/emptyContent";
import { CreateTransactionModal } from "@components/common/createTransactionModal";
import { CreateInterTransactionModal } from "@components/common/createInterTransactionModal";
import { DeleteOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import { PageHeader } from "@components/common/pageHeader";
import { capitalizeFirstLetter, getFirstLetter } from "@helpers/tools";

import { withMutateTransact } from "@helpers/hoc/withMutations";
import { withQueryFormDetails } from "@helpers/hoc/withQueries";
import { EditTransactionModal } from "@components/common/editTransactionModal";

import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";
import { sleep } from "antd-mobile/es/utils/sleep";
import styled from "styled-components";
import SEO from "@components/common/seo";
import { getUser } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";
import { formatISODate } from "@helpers/tools";
import dayjs from "dayjs";

type Props = {
  search?: {
    search: string;
  };
  location: object;
  formDetailsData: object;
  createTransaction: Function;
  deleteTransaction: Function;
  updateTransaction: Function;
};

const Ledgers = ({
  createTransaction,
  deleteTransaction,
  updateTransaction,
  formDetailsData,
}: Props) => {
  const { id } = getUser();
  const [editID, setEditID] = useState();
  const [isEditTranModalVis, setIsEditTranModalVis] = useState(false);
  const [isCreateTransactModalVisible, setIsCreateTransactModalVisible] =
    useState(false);
  const [
    isCreateInterTransactModalVisible,
    setIsCreateInterTransactModalVisible,
  ] = useState(false);
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const {
    data: transactionsData,
    loading: transactionsLoading,
    fetchMore: transactionsFetchMore,
  } = useQuery(queries.transactions, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      filters: {
        user: { id: { eq: Number(id) } },
      },
      sort: "date_time:desc",
    },
  });

  const hasMore = !(
    transactionsData?.transactions?.data?.length ===
    transactionsData?.transactions?.meta?.pagination?.total
  );

  const { data: transactSummariesData } = useQuery(queries.transactSummaries);

  const currMonthName = dayjs().format("MMMM");

  return (
    <LedgersWrapper>
      <SEO title="Ledgers" />
      <PageHeader
        title={`${currMonthName} Ledgers`}
        statisticTitle={{
          first: `${currMonthName} Transact Net`,
          firstAmt:
            transactSummariesData?.transactSummaries?.data?.currMonthNetTran,
          second: `${currMonthName} Transact In`,
          secondAmt:
            transactSummariesData?.transactSummaries?.data?.currMonthTranIn,
          third: `${currMonthName} Transact Out`,
          thirdAmt:
            transactSummariesData?.transactSummaries?.data?.currMonthTranOut,
        }}
        handleCreateTransaction={() => setIsCreateTransactModalVisible(true)}
        handleCreateInterTransaction={() =>
          setIsCreateInterTransactModalVisible(true)
        }
      />

      <Divider orientation="left">{currMonthName} Transactions</Divider>
      <div className="mx-10">
        {transactionsLoading ? (
          <Skeleton
            avatar
            title={true}
            loading={true}
            active
            paragraph={{ rows: 10 }}
          />
        ) : (
          <>
            {transactionsData?.transactions?.data?.length > 0 ? (
              <>
                {transactionsData?.transactions?.data?.map((res, index) => {
                  return (
                    <List
                      key={index}
                      style={{ marginBottom: "1.5rem" }}
                      header={
                        <div>
                          <Row>
                            <Col span={12}>
                              {formatISODate(res.attributes.date_time)}
                              <Divider type="vertical" />
                              {capitalizeFirstLetter(
                                res?.attributes?.transaction_type?.data
                                  ?.attributes?.name
                              )}
                              <Divider type="vertical" />
                              {res.attributes.amount}{" "}
                              {res.attributes.account.data.attributes.currency.data.attributes.name.toUpperCase()}
                              <Divider type="vertical" />
                            </Col>
                            <Col span={12}>
                              <Dropdown.Button
                                className="justify-end"
                                onClick={() => {
                                  console.log(res);
                                  setEditID(Number(res.id));
                                  setIsEditTranModalVis(true);
                                  form.setFieldsValue({
                                    transaction_type:
                                      res?.attributes?.transaction_type?.data
                                        ?.id,
                                    account: res?.attributes?.account?.data?.id,
                                    transaction_tag:
                                      res?.attributes?.transaction_tag?.data
                                        ?.id,
                                    date_time: dayjs(
                                      res?.attributes?.date_time
                                    ),
                                    amount: Number(res?.attributes?.amount),
                                    remark: res?.attributes?.remark,
                                  });
                                }}
                                size="small"
                                loading={false}
                                menu={{
                                  items: [
                                    {
                                      key: "1",
                                      label: "Delete",
                                      icon: <DeleteOutlined />,
                                    },
                                  ],
                                  onClick: () => {
                                    Modal.confirm({
                                      title:
                                        "Confirm to Delete this Transaction?",
                                      icon: <DeleteFilled />,
                                      okType: "danger",
                                      okText: (
                                        <>
                                          <DeleteOutlined /> Yes
                                        </>
                                      ),
                                      onOk() {
                                        console.log("OK");
                                        deleteTransaction({
                                          variables: { id: res.id },
                                        });
                                      },
                                      onCancel() {
                                        console.log("Cancel");
                                      },
                                    });
                                  },
                                }}
                              >
                                <EditOutlined />
                                Edit
                              </Dropdown.Button>
                            </Col>
                          </Row>
                        </div>
                      }
                      itemLayout="horizontal"
                      dataSource={[res.attributes]}
                      renderItem={item => (
                        <List.Item>
                          <List.Item.Meta
                            className="mt-0"
                            avatar={
                              <Avatar>
                                {getFirstLetter(
                                  item.transaction_tag?.data.attributes.name
                                )}
                              </Avatar>
                            }
                            title={
                              <div>
                                {capitalizeFirstLetter(
                                  item.transaction_tag?.data.attributes.name
                                )}
                              </div>
                            }
                            description={capitalizeFirstLetter(
                              item.account?.data.attributes.name
                            )}
                          />
                          <div>
                            {res?.attributes?.transaction_type?.data?.id === "1"
                              ? "+"
                              : "-"}
                            {item.amount}
                          </div>
                        </List.Item>
                      )}
                    />
                  );
                })}
                <div className="w-full mb-3 text-center">
                  <InfiniteScroll
                    loadMore={async () => {
                      const counts = 15;
                      transactionsFetchMore({
                        variables: {
                          pagination: {
                            pageSize: pageSize + counts,
                          },
                        },
                      });
                      setPageSize(pageSize + counts);
                      await sleep(2000);
                    }}
                    hasMore={hasMore}
                  >
                    <>
                      {hasMore ? (
                        <>
                          <span>Loading</span>
                          <DotLoading />
                        </>
                      ) : (
                        <span>--- All Fetched ---</span>
                      )}
                    </>
                  </InfiniteScroll>
                </div>
              </>
            ) : (
              <EmptyContent />
            )}
          </>
        )}
      </div>

      <EditTransactionModal
        formDetailsData={formDetailsData}
        updateTransaction={updateTransaction}
        Form={Form}
        form={form}
        isEditTranModalVis={isEditTranModalVis}
        editID={editID}
        setIsEditTranModalVis={setIsEditTranModalVis}
        onHandleFormSubmit={values => {
          values.amount = Number(values.amount);
          updateTransaction({
            variables: {
              id: editID,
              data: values,
            },
          });
          setIsEditTranModalVis(false);
        }}
      />
      <CreateTransactionModal
        isModalVisible={isCreateTransactModalVisible}
        setIsModalVisible={setIsCreateTransactModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={(values, form) => {
          values.user = id;
          values.amount = Number(values.amount);

          createTransaction({
            variables: {
              data: values,
            },
          });
          form.resetFields();
          setIsCreateTransactModalVisible(false);
        }}
      />
      <CreateInterTransactionModal
        isModalVisible={isCreateInterTransactModalVisible}
        setIsModalVisible={setIsCreateInterTransactModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={(values, form) => {
          console.log("Received values of form: ", values);
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 2,
                account: values.from_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: id,
              },
            },
          });
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 1,
                account: values.to_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: id,
              },
            },
          });
          form.resetFields();
          setIsCreateInterTransactModalVisible(false);
        }}
      />
    </LedgersWrapper>
  );
};

export default withQueryFormDetails(withMutateTransact(withLocation(Ledgers)));

const LedgersWrapper = styled.div`
  && {
    margin: 0 auto;
  }
  .ant-list-item-meta-title {
    margin-top: 0;
  }
`;
